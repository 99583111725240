import { useEffect, useRef, useState, useMemo } from 'react';
import mqtt from 'mqtt/dist/mqtt.esm';
import isEqual from 'lodash.isequal';

import { generateShortUID, getClientId } from '~/utils/client';
import { getQueryParam } from '~/utils/url';
import {
  CampaignContainer,
  CampaignContent,
  TextCampaign,
  TextCampaignInternal,
  isImageCampaign,
  isTextCampaign,
  isVideoCampaign,
} from '~/types/Campaign';
import { useStore } from './useStore';

const statusTopic = (configId: string, config: DPI.MQTTConfig) => `${config.topickey}/public/dpi/sign/${configId}/diagnostics`;

export default function useMQTT() {
  const { setCampaigns, mqtt: mqttConfig, tarrifZones } = useStore();
  const [topics, setTopics] = useState<string[]>([]);
  const [campaignByTopic, setCampaignByTopic] = useState<Record<string, CampaignContent[]>>({});
  const client = useRef<mqtt.MqttClient | null>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>();

  function connect(screenConfig: DPI.ScreenConfig, mqttConfig: DPI.MQTTConfig, outOfService: boolean) {
    if (!screenConfig || !mqttConfig) {
      return null;
    }
    
    const configId = screenConfig.id;
    const runAsSnapShotTest = getQueryParam('runAsTest');
    const runAsPreview = getQueryParam('preview');
    if (runAsSnapShotTest) {
      return;
    }
    const host = mqttConfig.host;

    if (!client.current) {
      client.current = mqtt.connect({
        host: host,
        path: "/mqtt",
        protocol: 'wss',
        port: 9883,
        clientId: configId || `${generateShortUID()}-${generateShortUID()}`,
        username: mqttConfig.user,
        password: mqttConfig.password,
        clean: false,
        reconnectPeriod: 1000,
        protocolVersion: 5,
        properties: {
          sessionExpiryInterval: 60_000,
        },
        will: runAsPreview
          ? undefined
          : {
              topic: statusTopic(configId, mqttConfig),
              qos: 1,
              retain: true,
              payload: JSON.stringify(createStatusMessage(screenConfig, outOfService, false)) as unknown as Buffer,
              properties: {
                willDelayInterval: 60,
                messageExpiryInterval: 5_184_000, // 60 days
              },
            },
      });

      client.current.on('error', (error) => {
        console.log('Unable to connect');
        console.log(error);
      });
      client.current.on('message', onMQTTMessage);
      client.current.on('connect', () => {
        if (runAsPreview) {
          return;
        }
        // Send status right away
        sendStatusMessage(screenConfig, mqttConfig, outOfService);
        // Send status message every 5min
        intervalRef.current = setInterval(() => {
          sendStatusMessage(screenConfig, mqttConfig, outOfService);
        }, 5 * 60 * 1000);
      });
    }
  }

  function disconnect() {
    if (client.current && client.current.connected) {
      client.current.end();
    }
    client.current = null;
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }

  function createStatusMessage(screenConfig: DPI.ScreenConfig, outOfService: boolean, alive = true) {
    const configId = screenConfig.id;
    const clientId = getClientId();
    const fullURL = window.location.href;
    return {
      eventTimestamp: new Date().toISOString(),
      clientId: clientId,
      configId: configId,
      alive: alive,
      type: 'STATUS',
      payload: {
        client: {
          version: __APP_VERSION__,
          lastLoaded: window._LAST_LOADED_,
          windowHeight: window.innerHeight,
          windowWidth: window.innerWidth,
          userAgent: window.navigator ? window.navigator.userAgent : null,
          url: fullURL,
        },
        config: {
          serviceMode: outOfService ? 'OUT_OF_SERVICE' : 'NORMAL',
          quayIds:
            screenConfig?.boards
              ?.flatMap((board) => board.quayIds)
              .filter((quayIds) => typeof quayIds !== 'undefined') || [],
          stopPlaceId:
            screenConfig?.boards
              ?.flatMap((board) => board.stopPlaceId)
              .filter((stopPlaceId) => typeof stopPlaceId !== 'undefined') || [],
        },
      },
    };
  }

  function sendStatusMessage(screenConfig: DPI.ScreenConfig, mqttConfig: DPI.MQTTConfig, outOfService: boolean) {
    const message = JSON.stringify(createStatusMessage(screenConfig, outOfService, true));

    const configId = screenConfig.id;
    if (client.current && configId) {
      client.current.publish(statusTopic(configId, mqttConfig), message, {
        retain: true,
        qos: 1,
        properties: {
          messageExpiryInterval: 5_184_000, // 60 days
        },
      });
    }
  }

  useEffect(
    function () {
      if (!client.current) {
        return;
      }
      console.log('Updated topics');
      client.current.unsubscribe(topics);
      client.current.subscribe(topics);
    },
    [topics]
  );

  function parseMessage(topic: string, messageBody: Buffer): CampaignContainer | null {
    try {
      const messageString = messageBody ? messageBody.toString() : null;
      return messageString ? JSON.parse(messageString) : null;
    } catch (err) {
      //const payloadString = messageString || '[empty payload]';
      // TODO: Error
      return null;
    }
  }

  function onMQTTMessage(topic: string, message: Buffer) {
    const data = parseMessage(topic, message);

    setCampaignByTopic((campaignByTopic) => {
      return {
        ...campaignByTopic,
        [topic]: data?.content || [],
      };
    });
  }

  useEffect(() => {
    // Flat map of unique content
    const filteredCampaigns = Object.values(campaignByTopic)
      .flat()
      .filter((item, i, arr) => {
        if (isTextCampaign(item)) {
          return arr.findIndex((fi) => isEqual(fi, item)) === i;
        }
        if (isImageCampaign(item)) {
          return arr.findIndex((fi) => isImageCampaign(fi) && isEqual(fi.src, item.src)) === i;
        }
        if (isVideoCampaign(item)) {
          return arr.findIndex((fi) => isVideoCampaign(fi) && isEqual(fi.src, item.src)) === i;
        }
        return false;
      })
      .reduce<CampaignContent[]>((sum, campaign) => {
        // Split text campaign
        if (isTextCampaign(campaign)) {
          return [...sum, ...splitTextCampaign(campaign)];
        }

        return [...sum, campaign];
      }, []);

    setCampaigns(filteredCampaigns);
  }, [campaignByTopic]);

  useEffect(() => {
    const topics = tarrifZones.map((item) => {
      const parts = item.toLowerCase().split(':');

      return `public/${mqttConfig?.topickey}/dpi/stopplace/pa/${parts.join('/')}`;
    });

    setTopics(topics);
  }, [tarrifZones, mqttConfig]);

  return {
    mqttConnect: connect,
    mqttDisconnect: disconnect,
  };
}

function splitTextCampaign(campaign: TextCampaign): TextCampaignInternal[] {
  const hasEnglish = campaign.message.en?.title || campaign.message.en?.paragraphs?.join('').length;
  const hasNorwegian = campaign.message.no?.title || campaign.message.no?.paragraphs?.join('').length;

  const list: TextCampaignInternal[] = [];

  if (hasEnglish) {
    list.push({
      ...campaign,
      message: campaign.message.en,
    } as TextCampaignInternal);
  }
  if (hasNorwegian) {
    list.push({
      ...campaign,
      message: campaign.message.no,
    });
  }

  return list;
}
